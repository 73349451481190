import {
  TimeField as MuiTimeField,
  TimeFieldProps as MuiTimeFieldProps,
} from '@mui/x-date-pickers';
import React from 'react';
import { Control, useController } from 'react-hook-form';

interface TimeFieldProps extends MuiTimeFieldProps<any> {
  name: string;
  control: Control<any>;
  style?: object;
}

export const TimeField = ({
  name,
  control,
  style,
  ...dateProps
}: TimeFieldProps) => {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid },
  } = useController({ name, control });

  return (
    <MuiTimeField
      ampm={false}
      clearable
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={ref}
      {...dateProps}
      sx={{
        ...dateProps.sx,
        '& .MuiInputBase-root': {
          bgcolor: 'background.input',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
        },
        '& input': {
          fontSize: 15,
          height: '48px',
          boxSizing: 'border-box',
        },
        '& fieldset': {
          border: invalid ? '1px solid #eb5757 !important' : 'unset',
        },
      }}
    />
  );
};
