/**
 *
 * SelectBox
 *
 */
import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import React, { memo } from 'react';

export interface SelectOption {
  label: string;
  value: number | string;
}

interface Props extends SelectProps<string> {
  options: SelectOption[];
}

export const Select = memo(({ options, ...props }: Props) => {
  return (
    <FormControl fullWidth>
      <MuiSelect
        size="small"
        required
        {...props}
        sx={{
          bgcolor: 'background.input',
          fontSize: 15,
          minHeight: '48px',
          '& fieldset': { border: 'none' },
          '& svg': { color: 'text.primary' },
          ...props.sx,
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
});
