// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes dot-typing {
  0% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;
  }
  16.667% {
    box-shadow: 1000px -10px 0 0 #eff2f7, 1012px 0 0 0 #eff2f7,
      1024px 0 0 0 #eff2f7;
  }
  33.333% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;
  }
  50% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px -10px 0 0 #eff2f7,
      1024px 0 0 0 #eff2f7;
  }
  66.667% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;
  }
  83.333% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7,
      1024px -10px 0 0 #eff2f7;
  }
  100% {
    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Typing/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,4EAA4E;EAC9E;EACA;IACE;0BACsB;EACxB;EACA;IACE,4EAA4E;EAC9E;EACA;IACE;0BACsB;EACxB;EACA;IACE,4EAA4E;EAC9E;EACA;IACE;8BAC0B;EAC5B;EACA;IACE,4EAA4E;EAC9E;AACF","sourcesContent":["@keyframes dot-typing {\n  0% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;\n  }\n  16.667% {\n    box-shadow: 1000px -10px 0 0 #eff2f7, 1012px 0 0 0 #eff2f7,\n      1024px 0 0 0 #eff2f7;\n  }\n  33.333% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;\n  }\n  50% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px -10px 0 0 #eff2f7,\n      1024px 0 0 0 #eff2f7;\n  }\n  66.667% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;\n  }\n  83.333% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7,\n      1024px -10px 0 0 #eff2f7;\n  }\n  100% {\n    box-shadow: 1000px 0 0 0 #eff2f7, 1012px 0 0 0 #eff2f7, 1024px 0 0 0 #eff2f7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
