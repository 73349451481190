import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { Control, useController } from 'react-hook-form';
import React from 'react';

export interface RadioOption {
  label: string;
  value: number | string;
}

export interface RadioGroupFieldProps extends RadioGroupProps {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options: RadioOption[];
}

export const RadioGroupField = ({
  name,
  control,
  label,
  disabled,
  required,
  options,
  ...radioProps
}: RadioGroupFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <FormControl
      disabled={disabled}
      margin="normal"
      error={invalid}
      sx={{ mt: label ? 2 : 0 }}
    >
      <FormLabel
        sx={{
          color: 'text.primary',
          fontSize: 14,
          '::after': {
            content: required ? '" *"' : '""',
            color: '#eb5757',
          },
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...radioProps}
        sx={{ ml: 1, ...radioProps.sx }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio size="small" />}
            label={option.label}
            sx={{ '.MuiFormControlLabel-label': { fontSize: 14 } }}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};
