import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loginSaga } from './saga';
import { AuthRequest, DataAuth, LoginState } from './types';

export const initialState: LoginState = {
  loading: false,
  dataAuth: JSON.parse(sessionStorage.getItem('chatgpt_user') ?? '{}'),
  error: false,
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest(state, action: PayloadAction<AuthRequest>) {
      state.loading = true;
      state.error = false;
    },
    loginSuccess(state, action: PayloadAction<DataAuth>) {
      state.loading = false;
      sessionStorage.setItem('chatgpt_user', JSON.stringify(action.payload));
      state.dataAuth = action.payload;
    },
    loginError(state) {
      state.loading = false;
      state.error = true;
    },

    logout(state) {
      sessionStorage.removeItem('chatgpt_user');
      state.dataAuth = {};
    },
  },
});

export const { actions: loginActions } = slice;

export const useLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLoginSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
