/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { getTheme } from 'styles/theme';
import { selectTheme } from 'styles/theme/slice/selectors';
import MainLayout from '../layout';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { BlogPage } from './pages/BlogPage/Loadable';
import { ChatPage } from './pages/ChatPage/Loadable';
import { ConversationPage } from './pages/ConversationPage/Loadable';
import { GeneratePostPage } from './pages/GeneratePostPage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { selectLogin } from './pages/LoginPage/slice/selectors';
import { SignupPage } from './pages/SignupPage/Loadable';
import { SummaryMeetingPage } from './pages/SummaryMeetingPage/Loadable';
import { VoicePage } from './pages/VoicePage/Loadable';
import { EmailNewsletterPage } from './pages/EmailNewsletterPage/Loadable';
import { useTranslation } from 'react-i18next';

const AuthRoute = (props: {
  type: 'PUBLIC' | 'PRIVATE';
  children: ReactElement;
}) => {
  const { type, children } = props;
  const { dataAuth } = useSelector(selectLogin);

  if (type === 'PRIVATE' && !dataAuth?.token) {
    return <Navigate to="/login" />;
  }

  return children;
};

export function App() {
  const themeKey = useSelector(selectTheme);
  const theme = useMemo(() => createTheme(getTheme(themeKey)), [themeKey]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'en-US') {
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute type="PRIVATE">
              <MainLayout />
            </AuthRoute>
          }
        >
          <Route path="" element={<Navigate to="chat" />} />
          <Route path="chat" element={<ChatPage />} />
          <Route path="conversation" element={<ConversationPage />} />
          <Route path="generate-post" element={<GeneratePostPage />} />
          <Route path="summary-meeting" element={<SummaryMeetingPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="email-newsletter" element={<EmailNewsletterPage />} />
          <Route path="voice" element={<VoicePage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignupPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
    </ThemeProvider>
  );
}
