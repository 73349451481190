import authApi from 'app/api/authApi';
import { call, put, takeLatest } from 'redux-saga/effects'; //
import { loginActions as actions } from '.';

function* loginRequestSaga(action: ReturnType<typeof actions.loginRequest>) {
  try {
    const res = yield call(authApi.login, action.payload);
    if (res.code === 500) {
    } else {
      yield put(actions.loginSuccess(res?.data));
    }
  } catch (error) {
    yield put(actions.loginError());
  }
}

export function* loginSaga() {
  yield takeLatest(actions.loginRequest.type, loginRequestSaga);
}
