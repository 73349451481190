import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
  
  .MuiTooltip-tooltip {
    margin-bottom: 0 !important;
  }

  .MuiMenuItem-gutters {
    font-size: 15px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.5) !important;
  }
`;
