import { Box, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  active?: boolean;
  link?: string;
  tooltip?: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

export const SideBarItem = ({
  active,
  link,
  tooltip,
  Icon,
  onClick,
}: Props) => {
  return (
    <Box
      sx={{
        height: '56px',
        width: '56px',
        my: 1,
        cursor: 'pointer',
        borderRadius: '8px',
        bgcolor: active ? 'background.sidebarMenuItemActive' : 'transparent',
        '& a, & > div': {
          height: '56px',
          width: '56px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      onClick={onClick}
    >
      <Tooltip
        title={tooltip}
        arrow
        placement="top"
        enterDelay={500}
        enterNextDelay={500}
        disableInteractive
      >
        {link ? (
          <NavLink to={link}>
            <Icon
              sx={{
                color: active ? 'secondary.main' : 'background.sidebarMenuItem',
              }}
            />
          </NavLink>
        ) : (
          <div>
            <Icon
              sx={{
                color: active ? 'secondary.main' : 'background.sidebarMenuItem',
              }}
            />
          </div>
        )}
      </Tooltip>
    </Box>
  );
};
