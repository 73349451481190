import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';
import { isSystemDark } from '..';

const selectSlice = (state: RootState) => state?.theme || initialState;

export const selectTheme = createSelector([selectSlice], (state) => {
  if (state.selected === 'system') {
    return isSystemDark ? 'dark' : 'light';
  }
  return state.selected;
});

export const selectEnableSource = createSelector(
  [selectSlice],
  (state) => state.enableSource
);
