import { PaletteMode } from '@mui/material';
import { amber, deepOrange, grey } from '@mui/material/colors';
import { ThemeKeyType } from './slice/types';

export const getTheme = (mode: PaletteMode) => ({
  palette: {
    ...(mode === 'dark'
      ? {
          mode: 'dark' as PaletteMode,
          primary: { main: '#eff2f7', light: '#36404a', grey: '#36404a' },
          secondary: { main: '#7269ef', light: '#aaa5f5' },
          background: {
            default: '#303841',
            paper: '#262e35',
            body: '#303841',
            input: '#36404a',
            micBtn: '#fff3',
            sidebar: '#36404a',
            sidebarSub: '#303841',
            sidebarMenuItem: '#a6b0cf',
            sidebarMenuItemActive: '#3e4a56',
          },
          border: { primary: '#36404a', secondary: '#7269ef' },
          text: {
            primary: '#eff2f7',
            secondary: '#abb4d2',
            body: '#a6b0cf',
            desc: '#a2a2a0',
          },
          divider: 'rgba(255, 255, 255, 0.12)',
          action: {
            disabled: 'rgba(255, 255, 255, 0.3)',
          },
        }
      : {
          mode: 'light' as PaletteMode,
          primary: { main: '#343a40', light: '#36404a', grey: '#36404a' },
          secondary: { main: '#7269ef', light: '#aaa5f5' },
          background: {
            default: '#f7f7ff',
            paper: '#fff',
            body: '#f7f7ff',
            input: '#e6ebf5',
            micBtn: '#0000001a',
            sidebar: '#e6ebf5',
            sidebarSub: '#f5f7fb',
            sidebarMenuItem: '#a6b0cf',
            sidebarMenuItemActive: '#f5f7fb',
          },
          border: { primary: '#f0eff5', secondary: '#7269ef' },
          text: {
            primary: '#343a40',
            secondary: '#858b9f',
            body: '#a6b0cf',
            desc: '#a2a2a0',
          },
          divider: 'rgba(0, 0, 0, 0.12)',
        }),
  },
  breakpoints: {
    values: { xs: 0, sm: 560, md: 992, lg: 1200, xl: 1500 },
  },
  typography: {
    fontFamily: 'Public Sans,sans-serif',
  },
  components: {
    overrides: {
      MuiSelect: {
        select: {
          '&:focus': {
            background: '$labelcolor',
          },
        },
      },
    },
  } as any,
});

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: {
      ...amber,
      ...(mode === 'dark' && {
        main: amber[300],
      }),
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    ...(mode === 'dark' && {
      background: {
        default: deepOrange[900],
        paper: deepOrange[900],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
            primary: grey[900],
            secondary: grey[800],
          }
        : {
            primary: '#fff',
            secondary: grey[500],
          }),
    },
  },
});

export const isSystemDark = window?.matchMedia
  ? window.matchMedia('(prefers-color-scheme: dark)')?.matches
  : undefined;

export function saveTheme(theme: ThemeKeyType) {
  window.localStorage && localStorage.setItem('selectedTheme', theme);
}

export function getThemeFromStorage(): ThemeKeyType | null {
  return window.localStorage
    ? (localStorage.getItem('selectedTheme') as ThemeKeyType) || null
    : null;
}
