/**
 *
 * Input
 *
 */
import {
  IconButton,
  InputBase,
  InputBaseProps,
  Paper,
  SvgIconTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { useRef } from 'react';

interface Props extends InputBaseProps {
  style?: object;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  onClickIcon?: () => void;
}

export const Input = ({ Icon, style, onClickIcon, ...inputProps }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef.current?.focus();
    onClickIcon && onClickIcon();
  };

  return (
    <Paper
      sx={{
        p: '4px 0',
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'background.input',
        boxShadow: 'none',
        minHeight: '48px',
        flex: 1,
        ...style,
      }}
    >
      <InputBase
        inputRef={inputRef}
        {...inputProps}
        sx={{
          ml: 2,
          flex: 1,
          fontSize: 15,
          '& input::placeholder,& textarea::placeholder': {
            color: 'text.secondary',
            opacity: 1,
          },
          ...inputProps.sx,
        }}
      />

      {Icon && (
        <IconButton sx={{ p: '10px' }} disableRipple onClick={handleClick}>
          <Icon fontSize="small" sx={{ color: 'text.secondary' }} />
        </IconButton>
      )}
    </Paper>
  );
};
