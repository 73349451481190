import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  IconButton,
  InputBase,
  InputBaseProps,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, RefObject } from 'react';
import { Control, useController } from 'react-hook-form';
import { Error } from './styles';

interface InputFileFieldProps extends InputBaseProps {
  name: string;
  control: Control<any>;
  desc: string;
  documentRef: RefObject<HTMLInputElement>;
  showPreview?: boolean;
  onShowPreview?: () => void;
}

export const InputFileField = ({
  name,
  desc,
  control,
  documentRef,
  showPreview,
  onShowPreview,
  ...inputProps
}: InputFileFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({ name, control });

  const handleRemoveDocument = () => {
    documentRef.current!.value = '';
    onChange(null);
  };

  return (
    <Box>
      <Box
        component="label"
        htmlFor="icon-button-file"
        onDragEnter={(event) => event.preventDefault()}
        onDragOver={(event) => event.preventDefault()}
        onDrop={(event) => {
          event.preventDefault();
          const files = Array.from(event.dataTransfer.files);
          if (files && files[0]) {
            onChange(files[0]);
          }
        }}
        sx={{
          display: 'grid',
          placeItems: 'center',
          cursor: 'pointer',
          width: '100%',
          p: 2,
          borderRadius: '5px',
          border: (theme) =>
            `1px solid ${
              invalid ? '#eb5757' : (theme.palette as any).border.primary
            }`,
          position: 'relative',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            display: value ? '' : 'none',
          }}
          onClick={handleRemoveDocument}
        >
          <CloseIcon />
        </IconButton>

        {showPreview ? (
          <Tooltip title="Preview">
            <IconButton
              sx={{
                position: 'absolute',
                left: 10,
                top: 10,
                display: value ? '' : 'none',
              }}
              onClick={onShowPreview}
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <CloudUploadOutlinedIcon sx={{ fontSize: 40 }} />
          <Typography
            variant="body2"
            sx={{ fontSize: '16px', ml: 1, lineHeight: '26px' }}
          >
            {value?.name?.length > 20
              ? value?.name?.slice(0, 20) + '...'
              : value?.name}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{ fontSize: '14px', textAlign: 'center' }}
        >
          {desc}
        </Typography>
        {inputProps.inputProps?.accept ? (
          <Typography
            variant="body2"
            sx={{ fontSize: '14px', textAlign: 'center' }}
          >
            ({inputProps.inputProps.accept})
          </Typography>
        ) : null}

        <InputBase
          id="icon-button-file"
          value={value?.fileName}
          type="file"
          name={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            if (files && files[0]) {
              onChange(files[0]);
            }
          }}
          onBlur={onBlur}
          inputRef={documentRef}
          {...inputProps}
          sx={{ display: 'none', ...inputProps.sx }}
        />
      </Box>
      {error && (
        <Error>
          <CloseIcon sx={{ fontSize: '14px', mr: '5px' }} />
          {error?.message}
        </Error>
      )}
    </Box>
  );
};
