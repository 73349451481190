/**
 *
 * ChatButton
 *
 */
import { Box, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { SvgIconComponent } from '@mui/icons-material';

interface Props extends IconButtonProps {
  tooltip?: string;
  icon: ReactElement<SvgIconComponent>;
}

export const ChatButton = ({ tooltip, icon, ...props }: Props) => {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton
          disableRipple
          {...props}
          sx={{
            p: '12px 16px',
            borderRadius: '8px',
            bgcolor: 'inherit',
            ...props.sx,
          }}
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  ) : (
    <IconButton
      disableRipple
      {...props}
      sx={{
        p: '12px 16px',
        borderRadius: '8px',
        bgcolor: 'inherit',
        ...props.sx,
      }}
    >
      {icon}
    </IconButton>
  );
};
