import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputBaseProps, SvgIconTypeMap, Box } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { ChangeEvent } from 'react';
import { Control, useController } from 'react-hook-form';
import { Error, StyledInput, StyledPaper } from './styles';

interface InputFieldProps extends InputBaseProps {
  name: string;
  control: Control<any>;
  style?: object;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  colorIcon?: string;
  onClickIcon?: () => void;
}

export const InputField = ({
  name,
  control,
  style,
  Icon,
  colorIcon,
  onClickIcon,
  ...inputProps
}: InputFieldProps) => {
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <Box>
      <StyledPaper
        invalid={invalid.toString()}
        sx={{ display: inputProps.type === 'file' ? 'none' : null }}
        styles={style}
      >
        <StyledInput
          id={name}
          value={inputProps.type === 'file' ? value?.fileName : value}
          name={name}
          onChange={
            inputProps.type === 'file'
              ? (event: ChangeEvent<HTMLInputElement>) => {
                  const files = event.target.files;
                  if (files && files[0]) {
                    onChange(files[0]);
                  }
                }
              : onChange
          }
          onBlur={onBlur}
          inputRef={ref}
          {...inputProps}
          onKeyDown={(e) => {
            if (inputProps.type === 'number') {
              if (['e', '-', '+'].includes(e.key)) {
                e.preventDefault();
              }
            }
          }}
        />

        {Icon && (
          <IconButton
            sx={{ p: '10px' }}
            disableRipple
            component="label"
            htmlFor={name}
            onClick={onClickIcon}
          >
            <Icon
              fontSize="small"
              sx={{ color: colorIcon || 'text.secondary' }}
            />
          </IconButton>
        )}
      </StyledPaper>
      {error && (
        <Error>
          <CloseIcon sx={{ fontSize: '14px', mr: '5px' }} />
          {error?.message}
        </Error>
      )}
    </Box>
  );
};
