/**
 *
 * Label
 *
 */
import { Box, Typography, TypographyProps } from '@mui/material';
import React, { memo } from 'react';

interface LabelProps extends TypographyProps {
  required?: boolean;
  htmlFor?: string;
}

export const Label = memo(
  ({ required, htmlFor, children, ...props }: LabelProps) => {
    return (
      <Box sx={{ ...props.sx }}>
        <Typography
          component="label"
          htmlFor={htmlFor}
          variant={props.variant || 'body2'}
          sx={{
            '::after': {
              content: required ? '" *"' : '""',
              color: '#eb5757',
            },
          }}
        >
          {children}
        </Typography>
      </Box>
    );
  }
);
