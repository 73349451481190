/**
 *
 * Typing
 *
 */
import React from 'react';
import './styles.css';
import { Box, styled } from '@mui/material';

interface Props {}

const DotTyping = styled('div')(({ theme }) => ({
  position: 'relative',
  left: '-1000px',
  width: '6px',
  height: '6px',
  borderRadius: '5px',
  // backgroundColor: theme.palette.text.primary,
  // color: theme.palette.text.primary,
  boxShadow: `1000px 0 0 0 ${theme.palette.text.primary}, 1012px 0 0 0 ${theme.palette.text.primary}, 1024px 0 0 0 ${theme.palette.text.primary}`,
  animation: 'dot-typing 1.5s infinite linear',
}));

export const Typing = (_: Props) => {
  return (
    <Box sx={{ display: 'inline-block', width: '30px' }}>
      <DotTyping />
    </Box>
  );
};
