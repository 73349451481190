/**
 *
 * Checkbox
 *
 */
import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material';
import React, { memo } from 'react';
import styled from 'styled-components';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 15,
  height: 15,
  boxShadow:
    theme?.palette?.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme?.palette?.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  // '.Mui-checked &': {
  //   // outline: '2px auto rgba(19,124,189,.6)',
  //   // boxShadow: '0 0 0 0.15rem rgba(114,105,239,.25)',
  //   // outlineOffset: 2,
  // },
  'input:focus ~ &': {
    boxShadow: '0 0 0 0.15rem rgba(114,105,239,.25)',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme?.palette?.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#7269ef',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 15,
    height: 15,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

export const Checkbox = memo((props: CheckboxProps) => {
  return (
    <MuiCheckbox
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
});
