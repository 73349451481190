import { translations } from 'locales/translations';
import { _t } from 'utils/messages';

export const messages = {
  setting: () => _t(translations.navbar.setting),
  chat: () => _t(translations.navbar.chat),
  conversation: () => _t(translations.navbar.conversation),
  generatePost: () => _t(translations.navbar.generatePost),
  summary: () => _t(translations.navbar.summary),
  blog: () => _t(translations.navbar.blog),
  emailNewsletter: () => _t(translations.navbar.emailNewsletter),
  voice: () => _t(translations.navbar.voice),
  logOut: () => _t(translations.navbar.logOut),
  language: () => _t(translations.navbar.language),
  source: () => _t(translations.navbar.source),
  on: () => _t(translations.navbar.on),
  off: () => _t(translations.navbar.off),
  theme: {
    light: () => _t(translations.navbar.theme.light),
    dark: () => _t(translations.navbar.theme.dark),
  },
};
