/**
 *
 * Button
 *
 */
import { Button as MuiButton, ButtonProps } from '@mui/material';
import React from 'react';

interface Props extends ButtonProps {
  text: string;
}

export const Button = ({ text, ...buttonProps }: Props) => {
  return (
    <MuiButton
      variant="contained"
      disableRipple
      {...buttonProps}
      sx={{
        bgcolor: '#7269ef',
        color: '#eff2f7',
        textTransform: 'inherit',
        ':hover': { bgcolor: '#6159cb' },
        boxShadow: 'none',
        ...buttonProps.sx,
      }}
    >
      {text}
    </MuiButton>
  );
};
