import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LanguageIcon from '@mui/icons-material/Language';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Box, Grid, Menu, MenuItem } from '@mui/material';
import { SideBarItem } from 'app/components';
import { useLoginSlice } from 'app/pages/LoginPage/slice';
import { allowTenant } from 'constant';
import { Language } from 'locales/i18n';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useThemeSlice } from 'styles/theme/slice';
import { selectEnableSource, selectTheme } from 'styles/theme/slice/selectors';
import { messages } from './messages';

const checkAllowTenant = (tenant: string) => {
  if (tenant.includes(allowTenant)) return true;
  return false;
};

const MainLayout = () => {
  const [openMenu, setOpenMenu] = useState<{
    name: string;
    anchorEl: null | HTMLElement;
  }>({ name: '', anchorEl: null });

  const theme = useSelector(selectTheme);
  const enableSource = useSelector(selectEnableSource);
  const dispatch = useDispatch();
  const { actions } = useThemeSlice();
  const { actions: authActions } = useLoginSlice();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const dataAuth = JSON.parse(localStorage.getItem('dataAuth') || '{}');

  const handleChangeThemeMode = () => {
    dispatch(actions.changeTheme(theme === 'dark' ? 'light' : 'dark'));
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    navigate('/login');
  };

  const handleOpenMenu = (event: MouseEvent<HTMLDivElement>, name: string) => {
    setOpenMenu({ name, anchorEl: event.currentTarget });
  };

  const handleCloseMenu = () => {
    setOpenMenu({ name: '', anchorEl: null });
  };

  const handleChangeLanguage = (language: Language) => {
    i18n.changeLanguage(language);
    handleCloseMenu();
  };

  const sideBarItems = [
    {
      link: '/chat',
      tooltip: t(messages.chat()),
      Icon: SmsOutlinedIcon,
      available: true,
    },
    {
      link: '/generate-post',
      tooltip: t(messages.generatePost()),
      Icon: ArticleOutlinedIcon,
      available: true,
    },
    {
      link: '/conversation',
      tooltip: t(messages.conversation()),
      Icon: MarkChatUnreadOutlinedIcon,
      available: false,
    },
    {
      link: '/summary-meeting',
      tooltip: t(messages.summary()),
      Icon: SummarizeOutlinedIcon,
      // available: checkAllowTenant(dataAuth?.tenant),
      available: true,
    },
    {
      link: '/blog',
      tooltip: t(messages.blog()),
      Icon: MenuBookIcon,
      available: true,
    },
    {
      link: '/email-newsletter',
      tooltip: t(messages.emailNewsletter()),
      Icon: NewspaperIcon,
      available: true,
    },
    {
      link: '/voice',
      tooltip: t(messages.voice()),
      Icon: GraphicEqIcon,
      available: false,
    },
  ];

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item sx={{ width: '75px', bgcolor: 'background.sidebar' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100vh',
            }}
          >
            <Box
              sx={{
                height: '70px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '& img': { height: '30px' },
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {sideBarItems
                .filter((item) => item.available)
                .map((item) => (
                  <SideBarItem
                    key={item.link}
                    link={item.link}
                    tooltip={item.tooltip}
                    Icon={item.Icon}
                    active={pathname === item.link}
                  />
                ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <SideBarItem
                tooltip={t(messages.language())}
                active={Boolean(openMenu.name === 'language')}
                Icon={LanguageIcon}
                onClick={(event) => handleOpenMenu(event, 'language')}
              />
              <SideBarItem
                tooltip={
                  theme === 'light'
                    ? t(messages.theme.dark())
                    : t(messages.theme.light())
                }
                Icon={
                  theme === 'light'
                    ? LightModeOutlinedIcon
                    : DarkModeOutlinedIcon
                }
                onClick={handleChangeThemeMode}
              />
              <SideBarItem
                tooltip={t(messages.setting())}
                active={Boolean(openMenu.name === 'setting')}
                Icon={SettingsOutlinedIcon}
                onClick={(event) => handleOpenMenu(event, 'setting')}
              />
            </Box>
          </Box>
        </Grid>
        <Outlet />
      </Grid>
      <Menu
        anchorEl={openMenu.anchorEl}
        open={Boolean(openMenu.anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{
          '& ul': { bgcolor: 'background.sidebarSub' },
          '& li': {
            fontSize: '15px',
            justifyContent: 'space-between',
            ':hover': { bgcolor: 'background.sidebarMenuItemActive' },
          },
        }}
      >
        <Box sx={{ width: 160, maxWidth: '100%' }}>
          {openMenu.name === 'setting' && (
            <>
              <MenuItem
                onClick={() =>
                  dispatch(actions.changeEnableSource(!enableSource))
                }
                sx={{ px: 3 }}
              >
                <Box>{t(messages.source())}</Box>
                <Box>{enableSource ? t(messages.on()) : t(messages.off())}</Box>
              </MenuItem>
              <MenuItem onClick={handleLogout} sx={{ px: 3 }}>
                {t(messages.logOut())}
                <LogoutRoundedIcon sx={{ fontSize: 16 }} />
              </MenuItem>
            </>
          )}
          {openMenu.name === 'language' &&
            languages.map((language) => (
              <MenuItem
                key={language.value}
                onClick={() => handleChangeLanguage(language.value)}
                sx={{ px: 3 }}
              >
                {language.label}
                {i18n.language === language.value && <CheckIcon />}
              </MenuItem>
            ))}
        </Box>
      </Menu>
    </Box>
  );
};

export const languages: SelectLanguage[] = [
  { label: 'English', value: 'en' },
  { label: '日本語', value: 'ja' },
];

interface SelectLanguage {
  label: string;
  value: Language;
}

export default MainLayout;
